import time from '@nrk/yr-time';
import { convertSymbolKeyToId } from '@nrk/yr-weather-symbols';
import { PageRenderingError } from '../../app/errors';
import { createPageUrl } from '../../app/redirects';
import settings from '../../app/settings';
import { getDetailedWindDescriptionWithDirection } from '../../lib/helpers/windDescriptionHelpers';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTimeLocale } from '../../lib/hooks/useTimeLocale';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { isCoastForecastLongIntervalWithWaterLevel, TCoastIntervalsByDay } from '../../model/coast';
import { ILocation } from '../../model/location';
import { IWarning } from '../../model/warning';
import { Abbreviation } from '../Abbreviation/Abbreviation';
import { ExpandableWarningList } from '../ExpandableWarningList/ExpandableWarningList';
import { FluidTable, IFluidTableColumn, IFluidTableRow } from '../FluidTable/FluidTable';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import { RelativeTime } from '../RelativeTime/RelativeTime';
import { Temperature } from '../Temperature/Temperature';
import { Text } from '../Text/Text';
import { Wave } from '../Wave/Wave';
import { WeatherSymbol } from '../WeatherSymbol/WeatherSymbol';
import { Wind } from '../Wind/Wind';
import { WindDescription } from '../WindDescription/WindDescription';
import './CoastForecastHourlyDialog.scss';

interface IProps {
  location: ILocation;
  hourlyIntervalsGroupedByDay: TCoastIntervalsByDay[];
  index: number;
  warnings: IWarning[];
}

export const CoastForecastHourlyDialog = (props: IProps) => {
  const { location, index, hourlyIntervalsGroupedByDay, warnings } = props;

  const localeCode = useLocaleCode();
  const translate = useTranslate();
  const timeLocale = useTimeLocale();

  const forecastIntervalGroupedByDay = hourlyIntervalsGroupedByDay[index];

  // Can be undefined query parameter is changed manually. See YR-4838.
  if (forecastIntervalGroupedByDay == null) {
    throw new PageRenderingError();
  }

  const { start, intervals } = forecastIntervalGroupedByDay;

  const param = settings.path.query.index;

  const closeUrl = createPageUrl({
    localeCode,
    pageId: 'coast',
    subpageId: 'forecast',
    locationId: location.id,
    urlPath: location.urlPath
  });

  const previousUrl = hourlyIntervalsGroupedByDay[index - 1]
    ? createPageUrl({
        localeCode,
        pageId: 'coast',
        subpageId: 'hourly-table',
        locationId: location.id,
        urlPath: location.urlPath,
        search: `?${param}=${index - 1}`
      })
    : undefined;

  const nextUrl = hourlyIntervalsGroupedByDay[index + 1]
    ? createPageUrl({
        localeCode,
        pageId: 'coast',
        subpageId: 'hourly-table',
        locationId: location.id,
        urlPath: location.urlPath,
        search: `?${param}=${index + 1}`
      })
    : undefined;

  const columns: IFluidTableColumn[] = [];
  columns.push({
    testId: 'time',
    visible: true,
    align: 'left',
    heading: (
      <Abbreviation title={translate('terminology/time/long')} abbreviation={translate('terminology/time/short')} />
    )
  });
  columns.push({
    testId: 'symbol',
    visible: true,
    align: 'center',
    heading: (
      <Abbreviation
        title={translate('terminology/weather/long')}
        abbreviation={translate('terminology/weather/short')}
      />
    )
  });
  columns.push({
    testId: 'temperature',
    visible: true,
    align: 'right',
    heading: (
      <Abbreviation
        title={translate('terminology/temperature/long')}
        abbreviation={translate('terminology/temperature/short')}
      />
    )
  });
  columns.push({
    testId: 'wave',
    visible: true,
    align: 'right',
    heading: translate('coastForecastHourlyDialog/tableHeader/waveHeight')
  });
  columns.push({
    testId: 'windWithGust',
    visible: true,
    align: 'right',
    heading: (
      <Abbreviation
        title={`${translate('terminology/windWithGust/long')} ${translate('units/metersPerSecond/short')}`}
        abbreviation={`${translate('terminology/windWithGust/short')} ${translate('units/metersPerSecond/short')}`}
      />
    )
  });
  columns.push({
    testId: 'windDescription',
    className: 'coast-forecast-hourly-dialog__wind-description',
    visible: true,
    align: 'left',
    heading: (
      <Abbreviation
        title={translate('terminology/windDescription/long')}
        abbreviation={translate('terminology/windDescription/short')}
      />
    )
  });

  const rows: IFluidTableRow[] = [];
  const timeformat = translate('units/timeformat');

  intervals.forEach(interval => {
    const { temperature, sea, wind } = interval;
    const intervalStart = time.create(interval.start).locale(timeLocale);
    const intervalEnd = time.create(interval.end).locale(timeLocale);
    const symbolId = convertSymbolKeyToId(
      isCoastForecastLongIntervalWithWaterLevel(interval)
        ? interval.symbolCode.next6Hours
        : interval.symbolCode.next1Hour
    );
    const cells = [];

    cells.push(
      intervalEnd.diff(intervalStart, 'hours') === 1 ? (
        <Text size="5" tabularNums={true}>
          <time dateTime={intervalStart.format(timeformat)}>{intervalStart.format('HH')}</time>
        </Text>
      ) : (
        <>
          <Text size="5" tabularNums={true}>
            <time dateTime={intervalStart.format(timeformat)}>{intervalStart.format('HH')}</time>
            {'–'}
            <time dateTime={intervalEnd.format(timeformat)}>{intervalEnd.format('HH')}</time>
          </Text>
        </>
      )
    );

    cells.push(
      <span className="coast-forecast-hourly-dialog__symbol">
        <WeatherSymbol id={symbolId} />
      </span>
    );

    cells.push(
      <Text size="4" tabularNums={true}>
        <Temperature value={temperature.value} decimal={false} renderScreenReaderLabel={false} />
      </Text>
    );

    cells.push(
      sea.wave.height != null ? (
        <Text size="4" tabularNums={true}>
          <Wave height={sea.wave.height} direction={{ type: 'icon', value: sea.wave.direction }} displayUnit={false} />
        </Text>
      ) : (
        ''
      )
    );

    cells.push(
      <Text size="4" tabularNums={true}>
        <Wind
          type="wind-and-gust"
          value={wind.speed}
          windGust={wind.gust}
          direction={wind.direction}
          displayArrow={true}
          displayUnit={false}
          decimal={false}
          screenReaderLabel={getDetailedWindDescriptionWithDirection(wind.speed, wind.direction, translate, wind.gust)}
          debug={{ caller: 'CoastForecastHourlyDialog', api: 'coastForecast', locationId: location.id }}
        />
      </Text>
    );

    cells.push(
      <Text size="4" tabularNums={true}>
        <WindDescription speed={wind.speed} gust={wind.gust} direction={interval.wind.direction} />
      </Text>
    );

    rows.push({ cells });
  });

  return (
    <ModalDialog
      renderOnServer={true}
      title={
        <RelativeTime
          type="relative-day-with-date-long"
          time={start}
          relativeTodayOnly={true}
          transform="sentence-case"
        />
      }
      onCloseUrl={closeUrl}
      nextUrl={nextUrl}
      previousUrl={previousUrl}
    >
      {warnings != null && warnings.length > 0 ? <ExpandableWarningList warnings={warnings} /> : null}
      <FluidTable
        columns={columns}
        rows={rows}
        tableCaption={translate('coastForecastHourlyDialog/accessibleFallback')}
      />
    </ModalDialog>
  );
};
