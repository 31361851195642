import { CoordinateWithOptionalY } from '../../model/coordinate';
import { GraphGridNew, TGraphGridNewVerticalLine } from '../GraphGridNew/GraphGridNew';
import { GraphLine, GRAPH_LINE_STROKE_WIDTH_BOLD } from '../GraphLine/GraphLine';
import { TCoastGraphOnHover } from './CoastGraph';
import { IWaterLevelGraphMetrics } from './helpers/metrics/waterLevelGraphMetrics';

interface IProps {
  height: number;
  verticalLines: TGraphGridNewVerticalLine[];
  currentHoverIndex?: number;
  onHover: TCoastGraphOnHover;
  onHoverCancel: () => void;
  waterLevelGraphCoordinates: {
    waterLevelForecastCoordinates: CoordinateWithOptionalY[];
    waterLevelPredictionCoordinates: CoordinateWithOptionalY[];
  };
  waterLevelGraphMetrics: IWaterLevelGraphMetrics;
}

export const CoastGraph__WaterLevel = (props: IProps) => {
  const {
    height,
    verticalLines,
    currentHoverIndex,
    onHover,
    onHoverCancel,
    waterLevelGraphCoordinates,
    waterLevelGraphMetrics
  } = props;

  return (
    <GraphGridNew
      height={height}
      verticalLines={verticalLines}
      horizontalLines={waterLevelGraphMetrics.horizontalLines}
      hoverType="column"
      hoverIndex={currentHoverIndex}
      onHover={hover => {
        onHover({ type: 'waterLevel', ...hover });
      }}
      onHoverCancel={onHoverCancel}
    >
      <GraphLine
        className="coast-graph__water-level-forecast"
        normalizedCoordinates={waterLevelGraphCoordinates.waterLevelForecastCoordinates}
        curved={true}
        strokeWidth={GRAPH_LINE_STROKE_WIDTH_BOLD}
      />

      <GraphLine
        className="coast-graph__water-level-prediction"
        normalizedCoordinates={waterLevelGraphCoordinates.waterLevelPredictionCoordinates}
        curved={true}
        strokeWidth={GRAPH_LINE_STROKE_WIDTH_BOLD}
      />
    </GraphGridNew>
  );
};
