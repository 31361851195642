import { Icon } from '@nrk/yr-icons';
import { convertSymbolKeyToId } from '@nrk/yr-weather-symbols';
import { createPageUrl } from '../../app/redirects';
import settings from '../../app/settings';
import { getDetailedWindDescriptionWithGust } from '../../lib/helpers/windDescriptionHelpers';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IAPICoastForecastDailyInterval } from '../../model/api/coast';
import { ILocation } from '../../model/location';
import { IWarning } from '../../model/warning';
import { DAILY_PERIODS } from '../../modelHelpers/weatherSymbol';
import { MinMaxTemperature } from '../MinMaxTemperature/MinMaxTemperature';
import { RelativeTime } from '../RelativeTime/RelativeTime';
import { Text } from '../Text/Text';
import { WarningsIconGroup } from '../WarningsIconGroup/WarningsIconGroup';
import { Wave } from '../Wave/Wave';
import { WeatherSymbol } from '../WeatherSymbol/WeatherSymbol';
import { Wind } from '../Wind/Wind';
import './CoastForecastDailyItem.scss';

interface IProps {
  daysFromNow: number;
  interval: IAPICoastForecastDailyInterval;
  location: ILocation;
  warnings: IWarning[];
}

export const CoastForecastDailyItem = (props: IProps) => {
  const { daysFromNow, interval, location, warnings } = props;
  const { sixHourSymbols, start, wind, sea, temperature } = interval;

  const hasWarnings = warnings != null && warnings.length > 0;

  const translate = useTranslate();
  const dailyCoastForecastSymbols = sixHourSymbols.map((symbol, index) => {
    if (symbol == null) {
      return <div className="coast-forecast-daily-item__empty-symbol" aria-hidden={true} key={index}></div>;
    }

    const oldSymbolId = convertSymbolKeyToId(symbol);

    return (
      <div className={`coast-forecast-daily-item__symbol coast-forecast-daily-item__symbol-${index}`} key={index}>
        <WeatherSymbol id={oldSymbolId} period={DAILY_PERIODS[index]} />
      </div>
    );
  });

  const localeCode = useLocaleCode();
  const param = settings.path.query.index;

  const hourlyCoastForecastUrl = createPageUrl({
    localeCode,
    pageId: 'coast',
    subpageId: 'hourly-table',
    locationId: location.id,
    urlPath: location.urlPath,
    search: `?${param}=${daysFromNow}`
  });

  const windGustLabel = getDetailedWindDescriptionWithGust(translate, wind.max, wind.maxGust);

  return (
    <li className="coast-forecast-daily-item">
      <div className="coast-forecast-daily-item__date-and-warnings">
        <Text
          as="a"
          size="4"
          weight="bold"
          className="coast-forecast-daily-item__item-date"
          title={translate('coastForecastDailyItem/linkTitle')}
          href={hourlyCoastForecastUrl}
        >
          <RelativeTime
            type="relative-day-with-date-medium"
            time={start}
            relativeTodayOnly={true}
            transform="sentence-case"
          />
        </Text>
        <div className="coast-forecast-daily-item__warnings">
          <div className="coast-forecast-daily-item__warnings-mobile">
            {hasWarnings && <WarningsIconGroup warnings={warnings} size={3} />}
          </div>
          <div className="coast-forecast-daily-item__warnings-desktop">
            {hasWarnings && <WarningsIconGroup warnings={warnings} size={4} stack={true} />}
          </div>
        </div>
      </div>
      <div className="coast-forecast-daily-item__symbols">{dailyCoastForecastSymbols}</div>

      <div className="coast-forecast-daily-item__forecast">
        <div className="coast-forecast-daily-item__wind">
          <div className="coast-forecast-daily-item__icon-wrapper">
            <Icon className="coast-forecast-daily-item__icon" id="icon-wind" size={2} />
          </div>
          <Text size="4" tabularNums={true}>
            <Wind
              type="wind-and-gust"
              value={wind.max}
              windGust={wind.maxGust}
              screenReaderLabel={windGustLabel}
              decimal={false}
              debug={{ caller: 'CoastForecastDailyItem', api: 'coastForecast', locationId: location.id }}
            />
          </Text>
        </div>
        <div className="coast-forecast-daily-item__wave-height">
          {sea.wave.maxHeight != null && (
            <>
              <div className="coast-forecast-daily-item__icon-wrapper">
                <Icon className="coast-forecast-daily-item__icon" id="icon-wave" size={2} />
              </div>
              <Text size="4" tabularNums={true}>
                <Wave height={sea.wave.maxHeight} />
              </Text>
            </>
          )}
        </div>
        <div className="coast-forecast-daily-item__temperature" role="text">
          <div className="coast-forecast-daily-item__icon-wrapper">
            <Icon className="coast-forecast-daily-item__icon" id="icon-temperature" size={2} />
          </div>
          <span className={'nrk-sr'}>{translate('coastForecastDailyList/headers/temperature/long')}:</span>
          <Text size="4" tabularNums={true}>
            <MinMaxTemperature temperature={temperature} />
          </Text>
        </div>
      </div>
      <div className="coast-forecast-daily-item__hour-by-hour" aria-hidden={true}>
        <Text size="5" className="coast-forecast-daily-item__hour-by-hour-text">
          {translate('coastForecastDailyItem/hourByHourText')}
        </Text>
        <Icon className="coast-forecast-daily-item__hour-by-hour-icon" id="icon-chevron-right" size={3}></Icon>
      </div>
    </li>
  );
};
