import { Icon, TIconId } from '@nrk/yr-icons';
import classNames from 'classnames';
import { ITideEntry } from '../../model/coast';
import { createTimeLabel } from '../../lib/helpers/time';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Text } from '../Text/Text';

interface IProps {
  tides: ITideEntry[];
  relativeDayLabel: JSX.Element;
  visuallyHiddenHeaders: boolean;
}
export const TideCard__Table = (props: IProps) => {
  const { tides, relativeDayLabel, visuallyHiddenHeaders } = props;
  const translate = useTranslate();

  const tableRows = tides.map(tide => {
    return (
      <tr className="tide-card__table-row" key={tide.time}>
        <td className="tide-card__table-cell">
          <div className="tide-card__table-tide">
            <Icon className="tide-card__table-icon" id={`icon-tide-${tide.type}` as TIconId} size={3} />
            <Text size="4">{createTimeLabel({ time: tide.time, translate, type: 'hour-with-minutes' })}</Text>
          </div>
        </td>
        <td className="tide-card__table-cell tide-card__table-cell--tide-value">
          <Text size="4">
            {tide.value} {translate('units/centimeter/short')}
          </Text>
        </td>
      </tr>
    );
  });

  return (
    <table className="tide-card-table">
      <caption className="nrk-sr">
        {translate('tideCard/table/caption')} {relativeDayLabel}
      </caption>
      <thead className={classNames({ 'nrk-sr': visuallyHiddenHeaders })}>
        <tr className="tide-card__table-row">
          <Text as="th" size="5" color="secondary" className="tide-card__table-header">
            {translate('tideCard/table/highLow')}
          </Text>
          <Text
            as="th"
            size="5"
            color="secondary"
            className="tide-card__table-header tide-card__table-header--tide-value"
          >
            {translate('tideCard/table/tide')}
          </Text>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};
