import { Icon } from '@nrk/yr-icons';
import classNames from 'classnames';
import { prefix } from '../../lib/style';
import './WaterArrow.scss';

interface IProps {
  direction?: number;
  speed: number;
  className?: string;
  size?: number;
}

export function WaterArrow(props: IProps) {
  const { direction, className = '', speed, size = 3 } = props;
  if (direction == null) {
    return null;
  }

  // We don't show direction if there is no speed
  if (speed < 0) {
    return (
      <div
        className={classNames(className, {
          'water-arrow': true
        })}
      ></div>
    );
  }

  const directionStyle = prefix('transform', `translate(-50%, -50%) rotate(${direction}deg)`);

  return (
    <div
      className={classNames(className, {
        'water-arrow': true
      })}
      data-testid="water-arrow"
      aria-hidden={true}
    >
      <div className="water-arrow__arrow" style={directionStyle}>
        <Icon id="icon-arrow" size={size} />
      </div>
    </div>
  );
}
