import { Heading } from '../Heading/Heading';
import { Text } from '../Text/Text';
import './EmptyState.scss';

interface IProps {
  title: string;
  children: React.ReactNode;
}

export function EmptyState(props: IProps) {
  const { title, children } = props;

  return (
    <div className="empty-state">
      <Heading level="h3" size="4">
        {title}
      </Heading>
      <Text size="4" color="secondary">
        {children}
      </Text>
    </div>
  );
}
