import {
  IAPICoastForecastDailyInterval,
  IAPICoastForecastLongInterval,
  IAPICoastForecastShortInterval
} from './api/coast';
import { IApiStatus } from './api/status';
import { TWarningEventType } from './warning';

/*
  List of weather warnings we display on the coast page
  Based on https://confluence.nrk.no/display/YRNO/Kystvarsel
*/
export const COAST_WEATHER_WARNINGS: TWarningEventType[] = [
  'StormSurge',
  'PolarLow',
  'Gale',
  'Lightning',
  'Wind',
  'Icing',
  'Rain',
  'RainFlood',
  'Snow'
];

export interface ICoastForecast {
  created: string;
  distanceFromLocation: number;
  displayUrl: string;
  url: string;
  dayIntervals: IAPICoastForecastDailyInterval[];
  shortIntervals: IAPICoastForecastShortInterval[];
  longIntervals: IAPICoastForecastLongInterval[];
  intervalsGroupedByDay: TCoastIntervalsByDay[];
}

export type TCoastIntervalsByDay = {
  start: string;
  end: string;
  intervals: Array<IAPICoastForecastShortInterval | IAPICoastForecastLongInterval>;
};

export interface ICoastForecastWithWaterLevel extends ICoastForecast {
  longIntervals: ICoastForecastLongIntervalWithWaterLevel[];
  intervalsGroupedByDay: TCoastIntervalsWithWaterLevelByDay[];
}

type TCoastIntervalsWithWaterLevelByDay = {
  start: string;
  end: string;
  intervals: Array<ICoastForecastShortIntervalWithWaterLevel | ICoastForecastLongIntervalWithWaterLevel>;
};
export interface ICoastForecastLongIntervalWithWaterLevel extends IAPICoastForecastLongInterval {
  waterLevel?: IWaterLevelEntry;
}

export interface ICoastForecastShortIntervalWithWaterLevel extends IAPICoastForecastShortInterval {
  waterLevel?: IWaterLevelEntry;
}
export interface ITide {
  url: string;
  displayUrl: string;
  zoneName: string;
  units: {
    value: 'cm';
  };
  tides: ITideEntry[];
  status: IApiStatus;
}

type TTideType = 'high' | 'low';

export interface ITideEntry {
  time: string;
  type: TTideType;
  value: number;
}

export interface IWaterLevel {
  url: string;
  displayUrl: string;
  zoneName: string;
  units: {
    value: 'cm';
    delay: 'min';
  };
  hours: IWaterLevelEntry[];
}

export interface IWaterLevelEntry {
  prediction: {
    value: number;
  };
  forecast?: {
    value: number;
  };
  time: string;
}

export function isCoastForecastLongIntervalWithWaterLevel(
  interval: ICoastForecastShortIntervalWithWaterLevel | ICoastForecastLongIntervalWithWaterLevel
): interval is ICoastForecastLongIntervalWithWaterLevel {
  return 'nominalStart' in interval === true;
}
