import { ICoastForecast } from '../../model/coast';
import { ILocation } from '../../model/location';
import { IWarning } from '../../model/warning';
import { CoastForecastDailyItem } from '../CoastForecastDailyItem/CoastForecastDailyItem';
import './CoastForecastDailyList.scss';
import { CoastForecastDailyList__Headers } from './CoastForecastDailyList__Headers';

interface IProps {
  coastForecast: ICoastForecast;
  location: ILocation;
  warnings: IWarning[][];
}

export const CoastForecastDailyList = (props: IProps) => {
  const { coastForecast, location, warnings } = props;

  const coastForecastRows = coastForecast.dayIntervals.map((interval, index) => {
    return (
      <CoastForecastDailyItem
        daysFromNow={index}
        interval={interval}
        key={index}
        location={location}
        warnings={warnings[index]}
      />
    );
  });

  return (
    <div className="coast-forecast-daily-list">
      <div className="escape-layout-container-padding">
        <CoastForecastDailyList__Headers />
        <ol className="coast-forecast-daily-list__list">{coastForecastRows}</ol>
      </div>
    </div>
  );
};
