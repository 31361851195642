import { CoordinateWithOptionalY } from '../../model/coordinate';
import { GraphLine } from '../GraphLine/GraphLine';
import { GraphTemperatureGradient } from '../GraphTemperatureGradient/GraphTemperatureGradient';
import './GraphTemperatureLine.scss';

interface IProps {
  testId?: string;
  strokeWidth?: number;
  outline?: boolean;
  dashed?: boolean;
  normalizedCoordinates?: CoordinateWithOptionalY[];
  normalizedZeroY: number;
}

export function GraphTemperatureLine(props: IProps) {
  const { testId, strokeWidth, outline, dashed, normalizedZeroY, normalizedCoordinates } = props;

  return (
    <g className="graph-temperature-line">
      <GraphLine
        dashed={dashed}
        curved={true}
        testId={testId}
        normalizedCoordinates={normalizedCoordinates}
        strokeWidth={strokeWidth}
        outlined={outline}
        renderStrokeAndFillElement={(id: string) => (
          <GraphTemperatureGradient color="default" id={id} normalizedZeroY={normalizedZeroY} />
        )}
      ></GraphLine>
    </g>
  );
}
