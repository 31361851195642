import yrTime from '@nrk/yr-time';
import {
  forEachForecastIntervals,
  forEachForecastLongIntervals,
  normalizeTimeBetweenStartAndEnd
} from '../../../lib/helpers/intervals';
import { normalizeValueWithinRange } from '../../../lib/helpers/math';
import { createTimeLabel } from '../../../lib/helpers/time';
import { IAPICoastForecastLongInterval, IAPICoastForecastShortInterval } from '../../../model/api/coast';
import { ICoastForecast, IWaterLevel, IWaterLevelEntry } from '../../../model/coast';
import { CoordinateWithOptionalY } from '../../../model/coordinate';
import { ITranslateFunction } from '../../../model/translate';
import { IGraphArrowColumn } from '../../GraphArrowsRow/GraphArrowsRow';
import { TGraphGridNewVerticalLine } from '../../GraphGridNew/GraphGridNew';
import { TGraphXAxisRowColumn, TGraphXAxisRowTick } from '../../GraphXAxisRow/GraphXAxisRow';
import { calculateSeaCurrentGraphMetrics } from './metrics/seaCurrentGraphMetrics';
import { calculateTemperatureGraphMetrics } from './metrics/temperatureGraphMetrics';
import { calculateWaterLevelGraphMetrics } from './metrics/waterLevelGraphMetrics';
import { calculateWaveHeightGraphMetrics } from './metrics/waveHeightGraphMetrics';
import { calculateWindGraphMetrics } from './metrics/windGraphMetrics';

export function createCoastGraphData({
  coastForecast,
  translate
}: {
  coastForecast: ICoastForecast;
  translate: ITranslateFunction;
}) {
  const { dayIntervals, longIntervals, shortIntervals } = coastForecast;

  const dayHeaderLabels: TGraphXAxisRowColumn[] = [];
  const hourHeaderLabels: TGraphXAxisRowTick[] = [];
  const verticalLines: TGraphGridNewVerticalLine[] = [];

  const start = longIntervals[0].nominalStart;
  // Since we render each point of the graph lines in the middle of each interval, we end the graph at the start of the last interval instead of the end.
  // This way we overshoot by half an interval instead of it looking like we are missing some data at the end.
  const end = longIntervals[longIntervals.length - 1].nominalStart;

  dayIntervals.forEach((day, index) => {
    const normalizedStart = normalizeTimeBetweenStartAndEnd({ start, end, time: day.start });
    // normalizedEnd for the last interval is based on `end` to ensure there is not a mismatch between the graph grid and day headers
    const normalizedEnd =
      index === dayIntervals.length - 1
        ? normalizeTimeBetweenStartAndEnd({ start, end, time: end })
        : normalizeTimeBetweenStartAndEnd({ start, end, time: day.end });
    const normalizedWidth = normalizedEnd - normalizedStart;

    dayHeaderLabels.push({
      label: createTimeLabel({
        time: day.start,
        type: 'day-with-date-short',
        transform: 'sentence-case',
        translate
      }),
      // The start of the first longInterval might not line up with the start of the first dayInterval,
      // so we force the first day label to be positioned at the start
      normalizedX: index === 0 ? 0 : normalizedStart,
      normalizedWidth
    });
  });

  longIntervals.forEach((interval, index) => {
    const hour = yrTime.create(interval.nominalStart).format('HH');

    // We use normalizedNominalX only for positioning vertical lines and weather symbols
    const normalizedNominalX = normalizeTimeBetweenStartAndEnd({
      start,
      end,
      time: interval.nominalStart
    });

    verticalLines.push({
      normalizedX: normalizedNominalX,
      type: hour === '00' && index !== longIntervals.length - 1 ? 'bold' : undefined
    });

    hourHeaderLabels.push({ normalizedX: normalizedNominalX, label: hour });
  });

  const temperatureGraphMetrics = calculateTemperatureGraphMetrics({ shortIntervals, longIntervals });
  const temperatureGraphCoordinates: {
    temperatureCoordinates: CoordinateWithOptionalY[];
    waterTemperatureCoordinates: CoordinateWithOptionalY[];
  } = {
    temperatureCoordinates: [],
    waterTemperatureCoordinates: []
  };

  const windGraphMetrics = calculateWindGraphMetrics({ shortIntervals, longIntervals });
  const windGraphCoordinates: {
    windCoordinates: CoordinateWithOptionalY[];
    windGustCoordinates: CoordinateWithOptionalY[];
  } = { windCoordinates: [], windGustCoordinates: [] };

  const waveHeightGraphMetrics = calculateWaveHeightGraphMetrics({ shortIntervals, longIntervals });
  const waveHeightGraphCoordinates: {
    waveHeightCoordinates: CoordinateWithOptionalY[];
  } = { waveHeightCoordinates: [] };

  const seaCurrentGraphMetrics = calculateSeaCurrentGraphMetrics({ shortIntervals, longIntervals });
  const seaCurrentGraphCoordinates: {
    seaCurrentCoordinates: CoordinateWithOptionalY[];
  } = { seaCurrentCoordinates: [] };

  forEachForecastIntervals({
    shortIntervals,
    longIntervals,
    intervalCallback: (normalizedX, interval) => {
      const { windCoordinates, windGustCoordinates } = windGraphCoordinates;
      const { waveHeightCoordinates } = waveHeightGraphCoordinates;
      const { seaCurrentCoordinates } = seaCurrentGraphCoordinates;
      const { temperatureCoordinates, waterTemperatureCoordinates } = temperatureGraphCoordinates;

      const { normalizedWindY, normalizedWindGustY } = calculateNormalizedWindValuesFromInterval({
        interval,
        graphMin: windGraphMetrics.graphDimensions.graphMin,
        graphMax: windGraphMetrics.graphDimensions.graphMax
      });

      windCoordinates.push([normalizedX, normalizedWindY]);
      windGustCoordinates.push([normalizedX, normalizedWindGustY]);

      const { normalizedWaveHeightY } = calculateNormalizedWaveHeightValuesFromInterval({
        interval,
        graphMin: waveHeightGraphMetrics.graphDimensions.graphMin,
        graphMax: waveHeightGraphMetrics.graphDimensions.graphMax
      });

      waveHeightCoordinates.push([normalizedX, normalizedWaveHeightY]);

      const { normalizedSeaCurrentY } = calculateNormalizedSeaCurrentValuesFromInterval({
        interval,
        graphMin: seaCurrentGraphMetrics.graphDimensions.graphMin,
        graphMax: seaCurrentGraphMetrics.graphDimensions.graphMax
      });

      seaCurrentCoordinates.push([normalizedX, normalizedSeaCurrentY]);

      const { normalizedTemperatureY, normalizedWaterTemperatureY } = calculateNormalizedTemperatureValuesFromInterval({
        interval,
        graphMin: temperatureGraphMetrics.graphDimensions.graphMin,
        graphMax: temperatureGraphMetrics.graphDimensions.graphMax
      });

      temperatureCoordinates.push([normalizedX, normalizedTemperatureY]);
      waterTemperatureCoordinates.push([normalizedX, normalizedWaterTemperatureY]);
    }
  });

  const windArrows: IGraphArrowColumn[] = [];
  const waveHeightArrows: IGraphArrowColumn[] = [];
  const seaCurrentArrows: IGraphArrowColumn[] = [];

  forEachForecastLongIntervals({
    longIntervals,
    intervalCallback: ({ normalizedX, normalizedWidth, interval, isLastInterval }) => {
      if (isLastInterval === false) {
        if (interval.wind.direction != null) {
          windArrows.push({
            direction: interval.wind.direction,
            speed: interval.wind.speed,
            normalizedX,
            normalizedWidth
          });
        }

        if (interval.sea.wave.direction != null) {
          waveHeightArrows.push({
            direction: interval.sea.wave.direction,
            // We use the same component for waveHeight and seaCurrent
            // So in the case of wave height we use height as speed, since the treshold for showing the arrow is the same.
            speed: interval.sea.wave.height ?? 0,
            normalizedX,
            normalizedWidth
          });
        }

        if (interval.sea.current.direction != null && interval.sea.current.speed != null) {
          seaCurrentArrows.push({
            direction: interval.sea.current.direction,
            speed: interval.sea.current.speed,
            normalizedX,
            normalizedWidth
          });
        }
      }
    }
  });

  return {
    start,
    end,
    dayHeaderLabels,
    hourHeaderLabels,
    verticalLines,
    temperatureGraphCoordinates,
    temperatureGraphMetrics,
    waveHeightGraphMetrics,
    waveHeightGraphCoordinates,
    waveHeightArrows,
    seaCurrentGraphMetrics,
    seaCurrentGraphCoordinates,
    seaCurrentArrows,
    windArrows,
    windGraphMetrics,
    windGraphCoordinates
  };
}

export function createWaterLevelGraphData({
  start,
  end,
  waterLevel
}: {
  start: string;
  end: string;
  waterLevel?: IWaterLevel;
}) {
  if (waterLevel == null) {
    return null;
  }

  const { hours } = waterLevel;

  const waterLevelGraphMetrics = calculateWaterLevelGraphMetrics({ hours });
  const waterLevelGraphCoordinates: {
    waterLevelForecastCoordinates: CoordinateWithOptionalY[];
    waterLevelPredictionCoordinates: CoordinateWithOptionalY[];
  } = { waterLevelForecastCoordinates: [], waterLevelPredictionCoordinates: [] };

  hours.forEach(hour => {
    const { waterLevelForecastCoordinates, waterLevelPredictionCoordinates } = waterLevelGraphCoordinates;
    const normalizedX = normalizeTimeBetweenStartAndEnd({
      start,
      end,
      time: hour.time
    });

    const {
      normalizedWaterLevelForecastY,
      normalizedWaterLevelPredictionY
    } = calculateNormalizedWaterLevelValuesFromHour({
      hour,
      graphMax: waterLevelGraphMetrics.graphDimensions.graphMax,
      graphMin: waterLevelGraphMetrics.graphDimensions.graphMin
    });

    waterLevelForecastCoordinates.push([normalizedX, normalizedWaterLevelForecastY]);
    waterLevelPredictionCoordinates.push([normalizedX, normalizedWaterLevelPredictionY]);
  });

  return { waterLevelGraphMetrics, waterLevelGraphCoordinates };
}

function calculateNormalizedTemperatureValuesFromInterval({
  interval,
  graphMin,
  graphMax
}: {
  interval: IAPICoastForecastShortInterval | IAPICoastForecastLongInterval;
  graphMin: number;
  graphMax: number;
}) {
  const normalizedTemperatureY = normalizeValueWithinRange(graphMin, graphMax, interval.temperature.value);

  const normalizedWaterTemperatureY =
    interval.sea.temperature.value != null
      ? normalizeValueWithinRange(graphMin, graphMax, interval.sea.temperature.value)
      : undefined;

  return {
    normalizedTemperatureY,
    normalizedWaterTemperatureY
  };
}

function calculateNormalizedWindValuesFromInterval({
  interval,
  graphMin,
  graphMax
}: {
  interval: IAPICoastForecastShortInterval | IAPICoastForecastLongInterval;
  graphMin: number;
  graphMax: number;
}) {
  const normalizedWindY = normalizeValueWithinRange(graphMin, graphMax, interval.wind.speed);
  const normalizedWindGustY =
    interval.wind.gust != null ? normalizeValueWithinRange(graphMin, graphMax, interval.wind.gust) : undefined;

  return { normalizedWindY, normalizedWindGustY };
}

function calculateNormalizedWaveHeightValuesFromInterval({
  interval,
  graphMin,
  graphMax
}: {
  interval: IAPICoastForecastShortInterval | IAPICoastForecastLongInterval;
  graphMin: number;
  graphMax: number;
}) {
  const normalizedWaveHeightY =
    interval.sea.wave.height != null
      ? normalizeValueWithinRange(graphMin, graphMax, interval.sea.wave.height)
      : undefined;

  return { normalizedWaveHeightY };
}

function calculateNormalizedSeaCurrentValuesFromInterval({
  interval,
  graphMin,
  graphMax
}: {
  interval: IAPICoastForecastShortInterval | IAPICoastForecastLongInterval;
  graphMin: number;
  graphMax: number;
}) {
  const normalizedSeaCurrentY =
    interval.sea.current.speed != null
      ? normalizeValueWithinRange(graphMin, graphMax, interval.sea.current.speed)
      : undefined;

  return { normalizedSeaCurrentY };
}

function calculateNormalizedWaterLevelValuesFromHour({
  hour,
  graphMin,
  graphMax
}: {
  hour: IWaterLevelEntry;
  graphMin: number;
  graphMax: number;
}) {
  const normalizedWaterLevelForecastY =
    hour.forecast != null ? normalizeValueWithinRange(graphMin, graphMax, hour.forecast.value) : undefined;
  const normalizedWaterLevelPredictionY = normalizeValueWithinRange(graphMin, graphMax, hour.prediction.value);

  return {
    normalizedWaterLevelForecastY,
    normalizedWaterLevelPredictionY
  };
}
