import { CoordinateWithOptionalY } from '../../model/coordinate';
import { GraphGridNew, TGraphGridNewVerticalLine } from '../GraphGridNew/GraphGridNew';
import { GraphLine, GRAPH_LINE_STROKE_WIDTH_BOLD, GRAPH_LINE_STROKE_WIDTH_THIN } from '../GraphLine/GraphLine';
import { TCoastGraphOnHover } from './CoastGraph';
import { IWindGraphMetrics } from './helpers/metrics/windGraphMetrics';

interface IProps {
  height: number;
  verticalLines: TGraphGridNewVerticalLine[];
  currentHoverIndex?: number;
  onHover: TCoastGraphOnHover;
  onHoverCancel: () => void;
  windGraphCoordinates: {
    windCoordinates: CoordinateWithOptionalY[];
    windGustCoordinates: CoordinateWithOptionalY[];
  };
  windGraphMetrics: IWindGraphMetrics;
}

export const CoastGraph__Wind = (props: IProps) => {
  const {
    height,
    verticalLines,
    currentHoverIndex,
    onHover,
    onHoverCancel,
    windGraphCoordinates,
    windGraphMetrics
  } = props;

  return (
    <GraphGridNew
      height={height}
      verticalLines={verticalLines}
      horizontalLines={windGraphMetrics.horizontalLines}
      hoverType="column"
      hoverIndex={currentHoverIndex}
      onHover={hover => {
        onHover({ type: 'wind', ...hover });
      }}
      onHoverCancel={onHoverCancel}
    >
      <GraphLine
        className="coast-graph__wind"
        normalizedCoordinates={windGraphCoordinates.windCoordinates}
        curved={true}
        strokeWidth={GRAPH_LINE_STROKE_WIDTH_BOLD}
      />
      <GraphLine
        className="coast-graph__wind"
        normalizedCoordinates={windGraphCoordinates.windGustCoordinates}
        curved={true}
        dashed={true}
        strokeWidth={GRAPH_LINE_STROKE_WIDTH_THIN}
      />
    </GraphGridNew>
  );
};
