// @ts-ignore
import { ICoastForecastLongIntervalWithWaterLevel } from '../../model/coast';
import { createTimeWithHourLabel } from '../../lib/helpers/time';
import { getWindSpeedWithDirectionAndGust } from '../../lib/helpers/windDescriptionHelpers';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { TCoastGraphHoverType } from './CoastGraph';
import { GraphHoverPanel, ITableRow } from '../GraphHoverPanel/GraphHoverPanel';
import { SeaCurrent } from '../SeaCurrent/SeaCurrent';
import { Temperature } from '../Temperature/Temperature';
import { Wave } from '../Wave/Wave';
import { Wind } from '../Wind/Wind';

interface IProps {
  type: TCoastGraphHoverType;
  x: number;
  y: number;
  longInterval: ICoastForecastLongIntervalWithWaterLevel;
}

export function CoastGraph__HoverPanel(props: IProps) {
  const { type, x, y, longInterval } = props;

  const translate = useTranslate();

  const hoverTitle = createTimeWithHourLabel({
    type: 'day-with-date-medium',
    time: longInterval.start,
    transform: 'sentence-case',
    translate
  });

  const rows: ITableRow[] = [];

  if (type === 'temperature') {
    rows.push({
      type: 'row',
      supported: true,
      heading: translate('coastForecastGraphs/legends/temperature/air'),
      data: <Temperature value={longInterval.temperature.value} decimal={false} />
    });

    rows.push({
      type: 'row',
      supported: true,
      heading: translate('coastForecastGraphs/legends/temperature/sea'),
      data:
        longInterval.sea.temperature.value != null ? (
          <Temperature value={longInterval.sea.temperature.value} decimal={false} />
        ) : (
          undefined
        )
    });
  }

  if (type === 'wind') {
    rows.push({
      type: 'row',
      supported: true,
      heading: translate('terminology/wind/long'),
      data: getWindSpeedWithDirectionAndGust(longInterval.wind.speed, longInterval.wind.direction, translate)
    });

    if (longInterval.wind.gust != null) {
      rows.push({
        type: 'row',
        supported: true,
        heading: translate('terminology/windGust/long'),
        data: <Wind value={longInterval.wind.gust} decimal={false} />
      });
    }
  }
  if (type === 'waveHeight') {
    rows.push({
      type: 'row',
      supported: true,
      heading: translate('terminology/waveHeight/long'),
      data:
        longInterval.sea.wave.height != null ? (
          <Wave
            height={longInterval.sea.wave.height}
            direction={{ type: 'text', value: longInterval.sea.wave.direction }}
          />
        ) : (
          undefined
        )
    });
  }

  if (type === 'seaCurrent') {
    rows.push({
      type: 'row',
      supported: true,
      heading: translate('terminology/seaCurrent'),
      data:
        longInterval.sea.current.speed != null ? (
          <SeaCurrent
            speed={longInterval.sea.current.speed}
            direction={{ type: 'text', value: longInterval.sea.current.direction }}
          />
        ) : (
          undefined
        )
    });
  }

  if (type === 'waterLevel') {
    rows.push({
      type: 'row',
      supported: true,
      heading: translate('terminology/waterLevelForecast'),
      data: longInterval.waterLevel?.forecast
        ? `${Math.round(longInterval.waterLevel.forecast.value)} ${translate('units/centimeter/short')}`
        : undefined
    });

    rows.push({
      type: 'row',
      supported: true,
      heading: translate('terminology/predictedTide'),
      data: longInterval.waterLevel
        ? `${Math.round(longInterval.waterLevel.prediction.value)} ${translate('units/centimeter/short')}`
        : undefined
    });
  }

  return <GraphHoverPanel title={hoverTitle} initialX={x} initialY={y} rows={rows} />;
}
