import { IAPICoastForecastLongInterval, IAPICoastForecastShortInterval } from '../../../../model/api/coast';
import { IGraphDimension } from '../../../../model/graph';
import { findExtremes } from '../../../../lib/helpers/array';
import { calculateGraphDimensions } from '../../../../lib/helpers/graph';
import { GRAPH_Y_AXIS_STRIDE } from '../../../../lib/helpers/graphConstants';
import { normalizeValueWithinRange } from '../../../../lib/helpers/math';
import { TGraphGridNewHorizontalLine } from '../../../GraphGridNew/GraphGridNew';
import { IGraphShellNewYAxisTicks } from '../../../GraphShellNew/GraphShellNew';

export interface IWaveHeightGraphMetrics {
  graphDimensions: IGraphDimension;
  yAxisTicks: IGraphShellNewYAxisTicks;
  horizontalLines: TGraphGridNewHorizontalLine[];
}

export function calculateWaveHeightGraphMetrics({
  longIntervals,
  shortIntervals
}: {
  longIntervals: IAPICoastForecastLongInterval[];
  shortIntervals: IAPICoastForecastShortInterval[];
}): IWaveHeightGraphMetrics {
  const graphDimensions = calculateWaveHeightGraphDimensions({ longIntervals, shortIntervals });

  const { yAxisTicks, horizontalLines } = calculateWaveHeightGraphYAxisTicksAndHorizontalLines({
    waveHeightGraphDimensions: graphDimensions
  });

  return { graphDimensions, yAxisTicks, horizontalLines };
}

function calculateWaveHeightGraphDimensions({
  longIntervals,
  shortIntervals
}: {
  longIntervals: IAPICoastForecastLongInterval[];
  shortIntervals: IAPICoastForecastShortInterval[];
}) {
  const shortIntervalWaveHeights = shortIntervals.map(interval => interval.sea.wave.height);
  const longIntervalWaveHeights = longIntervals.map(interval => interval.sea.wave.height);

  const allWaveHeightValues = [...shortIntervalWaveHeights, ...longIntervalWaveHeights];

  const { min, max } = findExtremes(allWaveHeightValues);

  return calculateGraphDimensions({
    stride: GRAPH_Y_AXIS_STRIDE['coast']['waveHeight'],
    max,
    min
  });
}

function calculateWaveHeightGraphYAxisTicksAndHorizontalLines({
  waveHeightGraphDimensions
}: {
  waveHeightGraphDimensions: IGraphDimension;
}) {
  const { graphMin, graphMax, stride } = waveHeightGraphDimensions;
  const yAxisTicks: IGraphShellNewYAxisTicks = { type: 'ticks', ticks: [] };
  const horizontalLines: TGraphGridNewHorizontalLine[] = [];
  for (let index = graphMin; index <= graphMax; index += stride) {
    const normalizedY = normalizeValueWithinRange(graphMin, graphMax, index);

    yAxisTicks.ticks.push({
      normalizedY,
      label: index === graphMax ? { type: 'icon', id: 'icon-wave' } : { type: 'value', value: index }
    });

    horizontalLines.push({ normalizedY });
  }

  return {
    yAxisTicks,
    horizontalLines
  };
}
