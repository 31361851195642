import { Icon } from '@nrk/yr-icons';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { LastUpdated } from '../LastUpdated/LastUpdated';
import { Text } from '../Text/Text';
import './CoastFooter.scss';

interface IProps {
  lastUpdated: string;
  url: string;
  displayUrl: string;
}

export const CoastFooter = (props: IProps) => {
  const { lastUpdated, url, displayUrl } = props;

  const translate = useTranslate();

  return (
    <div className="coast-footer">
      <div className="coast-footer__last-updated">
        <LastUpdated created={lastUpdated} type="coast" />
      </div>
      <div className="coast-footer__wave-warning">
        <Text size="5" color="secondary" className="coast-footer__wave-warning-text">
          {translate('coastFooter/waveForecast')}
        </Text>{' '}
        <Text
          as="a"
          size="5"
          isLink={true}
          className="coast-footer__wave-warning-link"
          href={url}
          target="_blank"
          rel="noopener"
        >
          <span className="coast-footer__wave-warning-link-text">{displayUrl}</span>
          <Icon id="icon-new-window" size={2} className="coast-footer__wave-warning-icon" />
        </Text>
      </div>
    </div>
  );
};
