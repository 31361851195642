import { useTranslate } from '../../lib/hooks/useTranslate';
import { Text } from '../Text/Text';

export const CoastForecastDailyList__Headers = () => {
  const translate = useTranslate();

  return (
    <div className="coast-forecast-daily-list__headers" aria-hidden={true}>
      <Text as="div" size="5" color="secondary" className="coast-forecast-daily-list__symbols-headers">
        <span className="coast-forecast-daily-list__header coast-forecast-daily-list__header--night">
          {translate('coastForecastDailyList/headers/night')}
        </span>
        <span className="coast-forecast-daily-list__header coast-forecast-daily-list__header--morning">
          {translate('coastForecastDailyList/headers/morning')}
        </span>
        <span className="coast-forecast-daily-list__header coast-forecast-daily-list__header--day">
          {translate('coastForecastDailyList/headers/day')}
        </span>
        <span className="coast-forecast-daily-list__header coast-forecast-daily-list__header--afternoon">
          {translate('coastForecastDailyList/headers/afternoon')}
        </span>
        <span className="coast-forecast-daily-list__header coast-forecast-daily-list__header--evening">
          {translate('coastForecastDailyList/headers/evening')}
        </span>
      </Text>
      <Text as="div" size="5" color="secondary" className="coast-forecast-daily-list__forecast-headers">
        <span className="coast-forecast-daily-list__header coast-forecast-daily-list__header--wind">
          {translate('coastForecastDailyList/headers/wind/short')}
        </span>
        <span className="coast-forecast-daily-list__header coast-forecast-daily-list__header--wave-height">
          {translate('coastForecastDailyList/headers/wave/short')}
        </span>
        <span className="coast-forecast-daily-list__header coast-forecast-daily-list__header--temperature">
          {translate('coastForecastDailyList/headers/temperature/short')}
        </span>
      </Text>
    </div>
  );
};
