import { useTranslate } from '../../lib/hooks/useTranslate';
import { Card__Empty } from '../Card/Card__Empty';
import { EmptyText } from '../EmptyText/EmptyText';

export const TideCard__Empty = () => {
  const translate = useTranslate();

  let tableRows = [];

  for (let i = 0; i < 2; i++) {
    tableRows.push(
      <tr className="tide-card__table-row" key={i}>
        <td className="tide-card__table-cell">
          <EmptyText type={'span'} length={6} />
        </td>
        <td className="tide-card__table-cell">
          <div>
            <EmptyText type={'span'} length={6} />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <Card__Empty renderFooter={true}>
      <div className="tide-card__empty-state" aria-hidden={true}>
        <EmptyText type="h3" length={8} className="tide-card__empty-header" />
        <table className="tide-card-table">
          <tbody>
            <tr className="tide-card__table-row">
              <th className="tide-card__table-header">{translate('tideCard/table/highLow')}</th>
              <th className="tide-card__table-header">{translate('tideCard/table/tide')}</th>
            </tr>
            {tableRows}
          </tbody>
        </table>
        <EmptyText type="h3" length={8} className="tide-card__empty-header" />
        <table className="tide-card-table">
          <tbody>
            <tr className="tide-card__table-row">
              <th className="tide-card__table-header">{translate('tideCard/table/highLow')}</th>
              <th className="tide-card__table-header">{translate('tideCard/table/tide')}</th>
            </tr>
            {tableRows}
          </tbody>
        </table>
      </div>
    </Card__Empty>
  );
};
