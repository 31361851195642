import { ITide } from '../../model/coast';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ClickableLink } from '../Clickable/Clickable';
import { InfoPanel } from '../InfoPanel/InfoPanel';
import { Text } from '../Text/Text';

interface IProps {
  tide: ITide;
  openedById: string;
  onClose: () => void;
}

export const TideCard__InfoPanel = (props: IProps) => {
  const { tide, openedById, onClose } = props;

  const translate = useTranslate();

  return (
    <InfoPanel title={translate('tideCard/infoPanel/title')} openedById={openedById} onClose={onClose}>
      <p>{translate('tideCard/infoPanel/body')}</p>
      <p>{translate('tideCard/infoPanel/chartDatum')}</p>
      <p>
        {translate('tideCard/infoPanel/details')}{' '}
        <ClickableLink href={tide.url} isExternal={true}>
          <Text size="4" isLink={true}>
            {tide.displayUrl}
          </Text>
        </ClickableLink>
        .
      </p>
    </InfoPanel>
  );
};
