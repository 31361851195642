import { CoordinateWithOptionalY } from '../../model/coordinate';
import { GraphGridNew, TGraphGridNewVerticalLine } from '../GraphGridNew/GraphGridNew';
import { GraphLine, GRAPH_LINE_STROKE_WIDTH_BOLD } from '../GraphLine/GraphLine';
import { TCoastGraphOnHover } from './CoastGraph';
import { IWaveHeightGraphMetrics } from './helpers/metrics/waveHeightGraphMetrics';

interface IProps {
  height: number;
  verticalLines: TGraphGridNewVerticalLine[];
  currentHoverIndex?: number;
  onHover: TCoastGraphOnHover;
  onHoverCancel: () => void;
  waveHeightGraphCoordinates: {
    waveHeightCoordinates: CoordinateWithOptionalY[];
  };
  waveHeightGraphMetrics: IWaveHeightGraphMetrics;
}

export const CoastGraph__WaveHeight = (props: IProps) => {
  const {
    height,
    verticalLines,
    currentHoverIndex,
    onHover,
    onHoverCancel,
    waveHeightGraphCoordinates,
    waveHeightGraphMetrics
  } = props;

  return (
    <GraphGridNew
      height={height}
      verticalLines={verticalLines}
      horizontalLines={waveHeightGraphMetrics.horizontalLines}
      hoverType="column"
      hoverIndex={currentHoverIndex}
      onHover={hover => {
        onHover({ type: 'waveHeight', ...hover });
      }}
      onHoverCancel={onHoverCancel}
    >
      <GraphLine
        className="coast-graph__wave-height"
        normalizedCoordinates={waveHeightGraphCoordinates.waveHeightCoordinates}
        curved={true}
        strokeWidth={GRAPH_LINE_STROKE_WIDTH_BOLD}
      />
    </GraphGridNew>
  );
};
