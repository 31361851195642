export function getTemperatureProbabilityLegend(normalizedZeroY: number) {
  if (normalizedZeroY < 0) {
    return 'temperature-probability-warm';
  } else if (normalizedZeroY > 1) {
    return 'temperature-probability-cold';
  }

  return 'temperature-probability';
}

export function getTemperatureCurveLegend(normalizedZeroY: number) {
  if (normalizedZeroY < 0) {
    return 'temperature-curve-warm';
  } else if (normalizedZeroY > 1) {
    return 'temperature-curve-cold';
  }

  return 'temperature-curve';
}
