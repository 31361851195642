import { nonNullable } from '../types';

export function findExtremes(numbers: Array<number | undefined>, round: boolean = true) {
  const filteredNumbers = numbers.filter(nonNullable);

  if (filteredNumbers.length === 0) {
    return { max: undefined, min: undefined };
  }

  const max = Math.max(...filteredNumbers);
  const min = Math.min(...filteredNumbers);

  return {
    max: round ? Math.ceil(max) : max,
    min: round ? Math.floor(min) : min
  };
}
