import { Icon } from '@nrk/yr-icons';
import { numberToString } from '../../lib/format';
import { getDirectionDescription } from '../../lib/helpers/direction';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { prefix } from '../../lib/style';
import './Wave.scss';

interface IProps {
  height: number;
  direction?: { type: 'text' | 'icon'; value?: number };
  displayUnit?: boolean;
}

export const Wave = (props: IProps) => {
  const { height, direction, displayUnit = true } = props;

  const translate = useTranslate();

  const unitShort = translate('units/meter/short');
  const unitLong = translate('units/meter/long');

  const directionStyle = direction?.value != null ? prefix('transform', `rotate(${direction?.value}deg)`) : undefined;
  const directionText =
    direction?.value != null
      ? ` ${translate('direction/label')} ${getDirectionDescription(direction.value, translate, false)}`
      : '';

  return (
    <span className="wave" role="text">
      <span className="wave__height">{numberToString(height, translate)} </span>
      {displayUnit && (
        <abbr className="wave__unit" title={unitLong}>
          {unitShort}
        </abbr>
      )}
      {direction != null && (
        <div className="wave__arrow" aria-hidden={true}>
          {direction.type === 'icon' && (
            <span className="wave__arrow-icon" style={directionStyle}>
              <Icon id="icon-arrow" size={3} />
            </span>
          )}
          {direction.type === 'text' && directionText}
        </div>
      )}
    </span>
  );
};
