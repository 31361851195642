import { Icon } from '@nrk/yr-icons';
import { numberToString } from '../../lib/format';
import { getDirectionDescription } from '../../lib/helpers/direction';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { prefix } from '../../lib/style';
import './SeaCurrent.scss';

interface IProps {
  speed: number;
  direction?: { type: 'text' | 'icon'; value?: number };
  displayUnit?: boolean;
}

export const SeaCurrent = (props: IProps) => {
  const { speed, direction, displayUnit = true } = props;

  const translate = useTranslate();

  const unitShort = translate('units/centimetersPerSecond/short');
  const unitLong = translate('units/centimetersPerSecond/long');
  const directionStyle = direction?.value != null ? prefix('transform', `rotate(${direction.value}deg)`) : undefined;
  const directionText =
    direction?.value != null
      ? ` ${translate('direction/label')} ${getDirectionDescription(direction.value, translate, false)}`
      : '';

  return (
    <span className="sea-current" role="text">
      <span className="sea-current__speed">{numberToString(speed, translate)} </span>
      {displayUnit && (
        <abbr className="sea-current__unit" title={unitLong}>
          {unitShort}
        </abbr>
      )}

      {direction?.value != null && (
        <div className="sea-current__arrow" aria-hidden={true}>
          {direction.type === 'icon' && (
            <span className="sea-current__arrow-icon" style={directionStyle}>
              <Icon id="icon-arrow" size={3} />
            </span>
          )}
          {direction.type === 'text' && directionText}
        </div>
      )}
    </span>
  );
};
