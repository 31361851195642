import { GraphRow } from '../GraphRow/GraphRow';
import { WaterArrow } from '../WaterArrow/WaterArrow';
import { WindArrow } from '../WindArrow/WindArrow';

export interface IGraphArrowTick {
  direction?: number;
  speed: number;
  normalizedX: number;
}

export interface IGraphArrowColumn {
  direction?: number;
  speed: number;
  normalizedX: number;
  normalizedWidth: number;
}

interface IGraphArrowTicksProps {
  type: 'ticks';
  arrowType?: IGraphArrowType;
  ticks: IGraphArrowTick[];
  hideEdgeTicks?: boolean;
}

interface IGraphArrowColumnsProps {
  type: 'columns';
  arrowType?: IGraphArrowType;
  columns: IGraphArrowColumn[];
}

type IGraphArrowType = 'water' | 'wind';

type IProps = IGraphArrowTicksProps | IGraphArrowColumnsProps;

const HEIGHT = 18;

export function GraphArrowsRow(props: IProps) {
  if (isTicks(props)) {
    const { arrowType = 'wind', ticks, hideEdgeTicks } = props;
    return (
      <GraphRow
        height={HEIGHT}
        type="ticks"
        ticks={ticks.map(arrow => ({
          normalizedX: arrow.normalizedX,
          content:
            arrowType === 'wind' ? (
              <WindArrow size={2} direction={arrow.direction} speed={arrow.speed} />
            ) : (
              <WaterArrow size={2} direction={arrow.direction} speed={arrow.speed} />
            )
        }))}
        hideEdgeTicks={hideEdgeTicks}
      />
    );
  }

  const { arrowType = 'wind', columns } = props;

  return (
    <GraphRow
      height={HEIGHT}
      type="columns"
      columns={columns.map(arrow => ({
        normalizedX: arrow.normalizedX,
        normalizedWidth: arrow.normalizedWidth,
        content:
          arrowType === 'wind' ? (
            <WindArrow size={2} direction={arrow.direction} speed={arrow.speed} />
          ) : (
            <WaterArrow size={2} direction={arrow.direction} speed={arrow.speed} />
          )
      }))}
    />
  );
}

function isTicks(props: IProps): props is IGraphArrowTicksProps {
  return props.type === 'ticks';
}
