import classNames from 'classnames';
import { HoverPanel } from '../HoverPanel/HoverPanel';
import { createTimeLabel } from '../../lib/helpers/time';
import './GraphHoverPanel.scss';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Text } from '../Text/Text';

export interface ITableRow {
  type: 'row';
  supported: boolean;
  heading: React.ReactNode | string;
  data?: React.ReactNode | string;
  time?: {
    type: 'month' | 'day' | 'hour';
    value: string;
  };
}

interface IHeadingRow {
  type: 'heading-row';
  heading: string;
}

export interface IDividerRow {
  type: 'divider';
  withPadding?: boolean;
}

interface IGapRow {
  type: 'gap';
}

interface IProps {
  title: string;
  initialX: number;
  initialY: number;
  rows: Array<ITableRow | IHeadingRow | IDividerRow | IGapRow>;
}

// Which time label type should be used for month, day, or hour
const TIME_LABEL_TYPES = {
  month: 'month-with-year-short',
  day: 'day-with-date-short',
  hour: 'hour'
} as const;

export function GraphHoverPanel(props: IProps) {
  const { title, initialX, initialY, rows } = props;

  const translate = useTranslate();

  const hasTime = rows.some(row => row.type === 'row' && row.time != null);

  // Filter out rows that should not be rendered
  const enabledRows = rows.filter(row => {
    if (row.type === 'row') {
      return row.supported;
    }

    return true;
  });

  // Make a list of the rows that should be rendered with a divider above.
  // This is necessary because after stripping out rows that should not be rendered
  // we might have a divider row followed by another divider row, or a divider row
  // followed by a heading row.
  const tableRowsWithDividerAbove: ITableRow[] = [];
  for (let i = 0; i < enabledRows.length; i++) {
    const row = enabledRows[i];

    if (row.type !== 'divider' && row.type !== 'gap') {
      continue;
    }

    // If there is no next row we don't need to add a divider
    const nextRow = enabledRows[i + 1];
    if (nextRow == null) {
      continue;
    }

    // The next row should be rendered with a divider above if it is a regular row
    if (nextRow.type === 'row') {
      tableRowsWithDividerAbove.push(nextRow);
    }
  }

  return (
    <HoverPanel title={title} initialX={initialX} initialY={initialY}>
      <Text as="table" size="5" tabularNums={true} className="graph-hover-panel">
        <thead className="nrk-sr">
          <tr>
            <td />
            <th>{translate('graphHoverPanel/value')}</th>
            {hasTime && <th>{translate('graphHoverPanel/time')}</th>}
          </tr>
        </thead>

        <tbody>
          {enabledRows.map((row, index) => {
            if (row.type === 'heading-row') {
              return (
                <tr key={row.heading} className="graph-hover-panel__row graph-hover-panel__row--is-new-row">
                  <Text
                    as="th"
                    size="4"
                    color="secondary"
                    className="graph-hover-panel__row-heading"
                    colSpan={hasTime ? 3 : 2}
                    scope="colgroup"
                  >
                    {row.heading}:
                  </Text>
                </tr>
              );
            }

            if (row.type === 'divider') {
              return (
                <tr key={initialX * initialY} className="graph-hover-panel__divider">
                  <th></th>
                  <td></td>
                </tr>
              );
            }

            if (row.type === 'gap') {
              return null;
            }

            if (row.supported === false) {
              return null;
            }

            const timeLabel =
              row.time != null
                ? createTimeLabel({ type: TIME_LABEL_TYPES[row.time.type], time: row.time.value, translate })
                : undefined;

            return (
              <tr
                key={index}
                className={classNames('graph-hover-panel__row', {
                  'graph-hover-panel__row--is-new-row': tableRowsWithDividerAbove.includes(row)
                })}
              >
                {row.heading != null && (
                  <th className="graph-hover-panel__type">
                    {row.heading}
                    {typeof row.heading === 'string' && ':'}
                  </th>
                )}
                <td
                  className={classNames('graph-hover-panel__data', {
                    'graph-hover-panel__data--empty': row.data == null
                  })}
                >
                  {row.data != null ? row.data : '–'}
                </td>
                {timeLabel != null ? (
                  <>
                    &nbsp;
                    <Text as="td" size="5" color="secondary" className="graph-hover-panel__time">
                      ({timeLabel})
                    </Text>
                  </>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </Text>
    </HoverPanel>
  );
}
