import time from '@nrk/yr-time';
import { convertSymbolKeyToId } from '@nrk/yr-weather-symbols';
import { createTimeLabel } from '../../lib/helpers/time';
import { getDetailedWindDescriptionWithDirection } from '../../lib/helpers/windDescriptionHelpers';
import { useTimeLocale } from '../../lib/hooks/useTimeLocale';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ICoastForecastWithWaterLevel, isCoastForecastLongIntervalWithWaterLevel } from '../../model/coast';
import { Abbreviation } from '../Abbreviation/Abbreviation';
import { FluidTable, IFluidTableColumn, IFluidTableRow } from '../FluidTable/FluidTable';
import { RelativeTime } from '../RelativeTime/RelativeTime';
import { SeaCurrent } from '../SeaCurrent/SeaCurrent';
import { Text } from '../Text/Text';
import { Temperature } from '../Temperature/Temperature';
import { Wave } from '../Wave/Wave';
import { WeatherSymbol } from '../WeatherSymbol/WeatherSymbol';
import { Wind } from '../Wind/Wind';
import './CoastTable.scss';
import { Heading } from '../Heading/Heading';

interface IProps {
  coastForecast: ICoastForecastWithWaterLevel;
}

export const CoastTable = (props: IProps) => {
  const { coastForecast } = props;
  const translate = useTranslate();
  const timeLocale = useTimeLocale();

  const columns: IFluidTableColumn[] = [];
  columns.push({
    testId: 'time',
    visible: true,
    align: 'left',
    heading: (
      <Abbreviation title={translate('terminology/time/long')} abbreviation={translate('terminology/time/short')} />
    )
  });
  columns.push({
    testId: 'symbol',
    visible: true,
    align: 'center',
    heading: (
      <Abbreviation
        title={translate('terminology/weather/long')}
        abbreviation={translate('terminology/weather/short')}
      />
    )
  });
  columns.push({
    testId: 'temperature',
    visible: true,
    align: 'right',
    heading: (
      <Abbreviation
        title={translate('terminology/temperature/long')}
        abbreviation={translate('terminology/temperature/short')}
      />
    )
  });
  columns.push({
    testId: 'wave',
    visible: true,
    align: 'right',
    heading: translate('coastForecastHourlyDialog/tableHeader/waveHeight')
  });
  columns.push({
    testId: 'windWithGust',
    visible: true,
    align: 'right',
    heading: (
      <Abbreviation
        title={`${translate('terminology/windWithGust/long')} ${translate('units/metersPerSecond/short')}`}
        abbreviation={`${translate('terminology/windWithGust/short')} ${translate('units/metersPerSecond/short')}`}
      />
    )
  });
  columns.push({
    testId: 'seaCurrent',
    visible: true,
    align: 'right',
    heading: `${translate('terminology/seaCurrent')} ${translate('units/centimetersPerSecond/short')}`
  });
  columns.push({
    testId: 'waterTemperature',
    visible: true,
    align: 'right',
    heading: (
      <Abbreviation
        title={`${translate('terminology/waterTemperature/long')}`}
        abbreviation={translate('terminology/waterTemperature/short')}
      />
    )
  });
  columns.push({
    testId: 'predictedTide',
    visible: true,
    align: 'right',
    heading: `${translate('terminology/predictedTide')} ${translate('units/centimeter/short')}`
  });
  columns.push({
    testId: 'waterLevelForecast',
    visible: true,
    align: 'right',
    heading: `${translate('terminology/waterLevelForecast')} ${translate('units/centimeter/short')}`
  });

  const detailedTables = coastForecast.intervalsGroupedByDay.map(intervalGroupedByDay => {
    const { start, intervals } = intervalGroupedByDay;
    const dateLabel = createTimeLabel({ time: start, type: 'day-with-date-long', translate });
    const tableCaption = translate('coastForecastDetailedTable/caption', { dateLabel });

    const rows: IFluidTableRow[] = [];
    const timeformat = translate('units/timeformat');

    intervals.forEach(interval => {
      const { temperature, sea, wind, waterLevel } = interval;
      const intervalStart = time.create(interval.start).locale(timeLocale);
      const intervalEnd = time.create(interval.end).locale(timeLocale);
      const symbolId = convertSymbolKeyToId(
        isCoastForecastLongIntervalWithWaterLevel(interval)
          ? interval.symbolCode.next6Hours
          : interval.symbolCode.next1Hour
      );
      const cells = [];

      cells.push(
        intervalEnd.diff(intervalStart, 'hours') === 1 ? (
          <Text size="5" tabularNums={true}>
            <time dateTime={intervalStart.format(timeformat)}>{intervalStart.format('HH')}</time>
          </Text>
        ) : (
          <>
            <Text size="5" tabularNums={true}>
              <time dateTime={intervalStart.format(timeformat)}>{intervalStart.format('HH')}</time>
              {'–'}
              <time dateTime={intervalEnd.format(timeformat)}>{intervalEnd.format('HH')}</time>
            </Text>
          </>
        )
      );

      cells.push(
        <span className="coast-table__symbol">
          <WeatherSymbol id={symbolId} />
        </span>
      );

      cells.push(
        <Text size="4" tabularNums={true}>
          <Temperature value={temperature.value} decimal={false} renderScreenReaderLabel={false} />
        </Text>
      );

      cells.push(
        sea.wave?.height != null ? (
          <Text size="4" tabularNums={true}>
            <Wave
              height={sea.wave.height}
              direction={{ type: 'icon', value: sea.wave.direction }}
              displayUnit={false}
            />
          </Text>
        ) : (
          ''
        )
      );

      cells.push(
        <Text size="4" tabularNums={true}>
          <Wind
            type="wind-and-gust"
            value={wind.speed}
            windGust={wind.gust}
            direction={wind.direction}
            displayArrow={true}
            displayUnit={false}
            decimal={false}
            screenReaderLabel={getDetailedWindDescriptionWithDirection(
              wind.speed,
              wind.direction,
              translate,
              wind.gust
            )}
            debug={{ caller: 'CoastTable', api: 'coastForecast' }}
          />
        </Text>
      );

      cells.push(
        sea.current.speed != null && sea.current.direction != null ? (
          <Text size="4" tabularNums={true}>
            <SeaCurrent
              speed={sea.current.speed}
              direction={{ type: 'icon', value: sea.current.direction }}
              displayUnit={false}
            />
          </Text>
        ) : (
          ''
        )
      );

      cells.push(
        sea.temperature.value != null ? (
          <Text size="4" tabularNums={true}>
            <Temperature value={sea.temperature.value} decimal={false} renderScreenReaderLabel={false} />
          </Text>
        ) : (
          ''
        )
      );

      cells.push(
        waterLevel ? (
          <Text size="4" tabularNums={true}>
            {Math.round(waterLevel.prediction.value)}
          </Text>
        ) : (
          ''
        )
      );
      cells.push(
        waterLevel?.forecast ? (
          <Text size="4" tabularNums={true}>
            {Math.round(waterLevel.forecast.value)}
          </Text>
        ) : (
          ''
        )
      );

      rows.push({ cells });
    });

    return (
      <div key={start} className="coast-table__table-container">
        <Heading level="h3" size="3" data-testid="coast-table-heading">
          <RelativeTime
            type="relative-day-with-date-long"
            time={start}
            relativeTodayOnly={true}
            transform="sentence-case"
          />
        </Heading>
        <div className="coast-table__table">
          <FluidTable columns={columns} rows={rows} tableCaption={tableCaption} />
        </div>
      </div>
    );
  });

  return <div className="coast-table">{detailedTables}</div>;
};
