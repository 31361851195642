import { IAPICoastForecastLongInterval, IAPICoastForecastShortInterval } from '../../../../model/api/coast';
import { IGraphDimension } from '../../../../model/graph';
import { findExtremes } from '../../../../lib/helpers/array';
import { calculateGraphDimensions } from '../../../../lib/helpers/graph';
import { GRAPH_Y_AXIS_STRIDE } from '../../../../lib/helpers/graphConstants';
import { normalizeValueWithinRange } from '../../../../lib/helpers/math';
import { TGraphGridNewHorizontalLine } from '../../../GraphGridNew/GraphGridNew';
import { IGraphShellNewYAxisTicks } from '../../../GraphShellNew/GraphShellNew';

export interface ITemperatureGraphMetics {
  normalizedZeroY: number;
  graphDimensions: IGraphDimension;
  yAxisTicks: IGraphShellNewYAxisTicks;
  horizontalLines: TGraphGridNewHorizontalLine[];
}

export function calculateTemperatureGraphMetrics({
  longIntervals,
  shortIntervals
}: {
  longIntervals: IAPICoastForecastLongInterval[];
  shortIntervals: IAPICoastForecastShortInterval[];
}): ITemperatureGraphMetics {
  const graphDimensions = calculateTemperatureGraphDimensions({ longIntervals, shortIntervals });
  const normalizedZeroY = normalizeValueWithinRange(graphDimensions.graphMin, graphDimensions.graphMax, 0);

  const { yAxisTicks, horizontalLines } = calculateTemperatureGraphYAxisTicksAndHorizontalLines({
    temperatureGraphDimensions: graphDimensions,
    normalizedZeroY
  });

  return { normalizedZeroY, graphDimensions, yAxisTicks, horizontalLines };
}

function calculateTemperatureGraphDimensions({
  longIntervals,
  shortIntervals
}: {
  longIntervals: IAPICoastForecastLongInterval[];
  shortIntervals: IAPICoastForecastShortInterval[];
}) {
  const shortIntervalTemperatures = shortIntervals.map(interval => interval.temperature.value);
  const shortIntervalWaterTemperatures = shortIntervals.map(interval => interval.sea.temperature.value);

  const longIntervalTemperatures = longIntervals.map(interval => interval.temperature.value);
  const longIntervalWaterTemperatures = longIntervals.map(interval => interval.sea.temperature.value);

  const allTemperatureValues = [
    ...shortIntervalTemperatures,
    ...shortIntervalWaterTemperatures,
    ...longIntervalTemperatures,
    ...longIntervalWaterTemperatures
  ];

  const { min, max } = findExtremes(allTemperatureValues);

  return calculateGraphDimensions({
    stride: GRAPH_Y_AXIS_STRIDE['coast']['temperature'],
    max,
    min,
    graphShouldGoBelowZero: true
  });
}

function calculateTemperatureGraphYAxisTicksAndHorizontalLines({
  temperatureGraphDimensions,
  normalizedZeroY
}: {
  temperatureGraphDimensions: IGraphDimension;
  normalizedZeroY: number;
}) {
  const { graphMin, graphMax, stride } = temperatureGraphDimensions;
  const yAxisTicks: IGraphShellNewYAxisTicks = { type: 'ticks', ticks: [] };
  const horizontalLines: TGraphGridNewHorizontalLine[] = [];
  for (let index = graphMin; index <= graphMax; index += stride) {
    const normalizedY = normalizeValueWithinRange(graphMin, graphMax, index);

    yAxisTicks.ticks.push({
      normalizedY,
      label: index === graphMax ? { type: 'icon', id: 'icon-temperature' } : { type: 'value', value: `${index}°` }
    });

    horizontalLines.push({ normalizedY, type: normalizedY === normalizedZeroY ? 'dotted' : undefined });
  }

  return {
    yAxisTicks,
    horizontalLines
  };
}
