import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Heading } from '../Heading/Heading';
import './HoverPanel.scss';

const PANEL_OFFSET = 15;

interface IProps {
  title: React.ReactNode | string;
  children: React.ReactNode;
  initialX: number;
  initialY: number;
}

export function HoverPanel(props: IProps) {
  const { title, children, initialX, initialY } = props;

  const hoverPanel = useRef<HTMLDivElement>(null);
  const style = useRef<{ left: string; top: string }>();
  const [visible, setVisible] = useState(false);

  const updateStyle = useCallback(
    (x: number, y: number) => {
      if (hoverPanel.current == null) {
        return;
      }

      let left = 0;
      let top = 0;

      const elementRect = hoverPanel.current.getBoundingClientRect();
      const viewportHeight = document.body.clientHeight;
      const viewportWidth = document.body.clientWidth;

      // Determine where the panel would fit around the pointer
      const hasRoomAbove = y >= elementRect.height + PANEL_OFFSET;
      const hasRoomBelow = viewportHeight - y >= elementRect.height + PANEL_OFFSET;
      const hasRoomToTheLeft = x >= elementRect.width + PANEL_OFFSET;
      const hasRoomToTheRight = viewportWidth - x >= elementRect.width + PANEL_OFFSET;

      // Position the panel to the right of the pointer if there is space,
      // otherwise position the panel to the left of the pointer if there is space,
      // otherwise center it horizontally.
      if (hasRoomToTheRight) {
        left = x + PANEL_OFFSET;
      } else if (hasRoomToTheLeft) {
        left = x - elementRect.width - PANEL_OFFSET;
      } else {
        left = viewportWidth / 2 - elementRect.width / 2;
      }

      // Position the panel below the pointer if there is space,
      // otherwise position the panel above the pointer if there is space,
      // otherwise center it vertically.
      if (hasRoomBelow) {
        top = y + PANEL_OFFSET;
      } else if (hasRoomAbove) {
        top = y - elementRect.height - PANEL_OFFSET;
      } else {
        top = viewportHeight / 2 - elementRect.height / 2;
      }

      style.current = {
        left: `${left}px`,
        top: `${top}px`
      };

      // Update the element's position directly when the user moves the mouse.
      hoverPanel.current.style.left = style.current.left;
      hoverPanel.current.style.top = style.current.top;
    },
    [hoverPanel]
  );

  useEffect(() => {
    function handleMouseMove(event: MouseEvent) {
      updateStyle(event.clientX, event.clientY);
    }

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [updateStyle]);

  useEffect(() => {
    updateStyle(initialX, initialY);

    setVisible(true);
  }, [updateStyle, initialX, initialY]);

  return (
    <div
      className={classNames('hover-panel', { 'hover-panel--visible': visible })}
      ref={hoverPanel}
      style={style.current}
    >
      <Heading level="h1" size="4" className="hover-panel__title">
        {title}
      </Heading>
      {children}
    </div>
  );
}
