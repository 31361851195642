import { pixelValueToRem } from '../../lib/formatGraphics';

interface IProps {
  height: number;
}

export function GraphGap(props: IProps) {
  const { height } = props;

  return <div style={{ height: pixelValueToRem(height) }} />;
}
