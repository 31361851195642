import { GraphGradient, IGradientStop } from '../GraphGradient/GraphGradient';
import './GraphTemperatureGradient.scss';

interface IProps {
  id: string;
  normalizedZeroY: number;
  color?: 'default' | 'lightened';
}

export function GraphTemperatureGradient(props: IProps) {
  const { id, normalizedZeroY, color = 'default' } = props;
  const stops: IGradientStop[] = [];

  if (normalizedZeroY < 0) {
    stops.push({ className: `graph-temperature-gradient__stop-warm-${color}`, normalizedY: 0 });
    stops.push({ className: `graph-temperature-gradient__stop-warm-${color}`, normalizedY: 1 });
  } else if (normalizedZeroY > 1) {
    stops.push({ className: `graph-temperature-gradient__stop-cold-${color}`, normalizedY: 0 });
    stops.push({ className: `graph-temperature-gradient__stop-cold-${color}`, normalizedY: 1 });
  } else {
    stops.push({ className: `graph-temperature-gradient__stop-cold-${color}`, normalizedY: normalizedZeroY });
    stops.push({ className: `graph-temperature-gradient__stop-warm-${color}`, normalizedY: normalizedZeroY });
  }

  return <GraphGradient id={id} stops={stops} />;
}
