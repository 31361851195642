import { IAPICoastForecastLongInterval } from '../../../model/api/coast';
import {
  ICoastForecast,
  ICoastForecastLongIntervalWithWaterLevel,
  ICoastForecastShortIntervalWithWaterLevel,
  ICoastForecastWithWaterLevel,
  IWaterLevel,
  IWaterLevelEntry,
  TCoastIntervalsByDay
} from '../../../model/coast';

export function createCoastIntervalsWithWaterLevel({
  coastForecast,
  waterLevel
}: {
  coastForecast?: ICoastForecast;
  waterLevel?: IWaterLevel;
}): ICoastForecastWithWaterLevel | undefined {
  if (coastForecast == null || waterLevel == null) {
    return coastForecast;
  }

  const intervalsWithWaterLevelGroupedByDay = getIntervalsWithWaterLevelGroupedByDay(
    waterLevel,
    coastForecast.intervalsGroupedByDay
  );

  const longIntervalsWithWaterLevel = getLongIntervalsWithWaterLevel({
    waterLevelHours: waterLevel.hours,
    longIntervals: coastForecast.longIntervals
  });

  return {
    ...coastForecast,
    intervalsGroupedByDay: intervalsWithWaterLevelGroupedByDay,
    longIntervals: longIntervalsWithWaterLevel
  };
}

function getLongIntervalsWithWaterLevel({
  waterLevelHours,
  longIntervals
}: {
  waterLevelHours: IWaterLevelEntry[];
  longIntervals: IAPICoastForecastLongInterval[];
}) {
  return longIntervals.map(longInterval => {
    const currentHour = waterLevelHours.find(hour => hour.time === longInterval.start);

    return { ...longInterval, waterLevel: currentHour };
  });
}

function getIntervalsWithWaterLevelGroupedByDay(
  waterLevel: IWaterLevel,
  coastForecastIntervalsGroupedByDay: TCoastIntervalsByDay[]
) {
  const { hours } = waterLevel;

  return coastForecastIntervalsGroupedByDay.map(coastForecastIntervals => {
    const { start, end } = coastForecastIntervals;
    const coastForecastIntervalsWithWaterLevel: Array<
      ICoastForecastShortIntervalWithWaterLevel | ICoastForecastLongIntervalWithWaterLevel
    > = coastForecastIntervals.intervals.map(interval => {
      const currentHour = hours.find(hour => hour.time === interval.start);

      return { ...interval, waterLevel: currentHour };
    });

    return { start, end, intervals: coastForecastIntervalsWithWaterLevel };
  });
}
