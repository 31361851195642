import { CoordinateWithOptionalY } from '../../model/coordinate';
import { GraphGridNew, TGraphGridNewVerticalLine } from '../GraphGridNew/GraphGridNew';
import { GRAPH_LINE_STROKE_WIDTH_BOLD, GRAPH_LINE_STROKE_WIDTH_THIN } from '../GraphLine/GraphLine';
import { GraphTemperatureLine } from '../GraphTemperatureLine/GraphTemperatureLine';
import { TCoastGraphOnHover } from './CoastGraph';
import { ITemperatureGraphMetics } from './helpers/metrics/temperatureGraphMetrics';

interface IProps {
  height: number;
  verticalLines: TGraphGridNewVerticalLine[];
  currentHoverIndex?: number;
  onHover: TCoastGraphOnHover;
  onHoverCancel: () => void;
  temperatureGraphCoordinates: {
    temperatureCoordinates: CoordinateWithOptionalY[];
    waterTemperatureCoordinates: CoordinateWithOptionalY[];
  };
  temperatureGraphMetrics: ITemperatureGraphMetics;
}

export const CoastGraph__Temperature = (props: IProps) => {
  const {
    height,
    verticalLines,
    currentHoverIndex,
    onHover,
    onHoverCancel,
    temperatureGraphCoordinates,
    temperatureGraphMetrics
  } = props;

  return (
    <GraphGridNew
      height={height}
      verticalLines={verticalLines}
      horizontalLines={temperatureGraphMetrics.horizontalLines}
      hoverType="column"
      hoverIndex={currentHoverIndex}
      onHover={hover => {
        onHover({ type: 'temperature', ...hover });
      }}
      onHoverCancel={onHoverCancel}
    >
      <GraphTemperatureLine
        dashed={true}
        strokeWidth={GRAPH_LINE_STROKE_WIDTH_THIN}
        normalizedZeroY={temperatureGraphMetrics.normalizedZeroY}
        normalizedCoordinates={temperatureGraphCoordinates.waterTemperatureCoordinates}
      />

      <GraphTemperatureLine
        strokeWidth={GRAPH_LINE_STROKE_WIDTH_BOLD}
        normalizedZeroY={temperatureGraphMetrics.normalizedZeroY}
        normalizedCoordinates={temperatureGraphCoordinates.temperatureCoordinates}
      />
    </GraphGridNew>
  );
};
