import { IWaterLevelEntry } from '../../../../model/coast';
import { IGraphDimension } from '../../../../model/graph';
import { findExtremes } from '../../../../lib/helpers/array';
import { calculateGraphDimensions } from '../../../../lib/helpers/graph';
import { GRAPH_Y_AXIS_STRIDE } from '../../../../lib/helpers/graphConstants';
import { normalizeValueWithinRange } from '../../../../lib/helpers/math';
import { TGraphGridNewHorizontalLine } from '../../../GraphGridNew/GraphGridNew';
import { IGraphShellNewYAxisTicks } from '../../../GraphShellNew/GraphShellNew';

export interface IWaterLevelGraphMetrics {
  graphDimensions: IGraphDimension;
  yAxisTicks: IGraphShellNewYAxisTicks;
  horizontalLines: TGraphGridNewHorizontalLine[];
}

export function calculateWaterLevelGraphMetrics({ hours }: { hours: IWaterLevelEntry[] }): IWaterLevelGraphMetrics {
  const graphDimensions = calculateWaterLevelGraphDimensions({ hours });

  const { yAxisTicks, horizontalLines } = calculateWaterLevelGraphYAxisTicksAndHorizontalLines({
    waterLevelGraphDimensions: graphDimensions
  });

  return { graphDimensions, yAxisTicks, horizontalLines };
}

function calculateWaterLevelGraphDimensions({ hours }: { hours: IWaterLevelEntry[] }) {
  const hoursWaterLevelForecasts = hours.map(hour => hour.forecast?.value);
  const hoursWaterLevelPredictions = hours.map(hour => hour.prediction.value);

  const allWaterLevelValues = [...hoursWaterLevelForecasts, ...hoursWaterLevelPredictions];

  const { min, max } = findExtremes(allWaterLevelValues);

  return calculateGraphDimensions({
    stride: GRAPH_Y_AXIS_STRIDE['coast']['waterLevel'],
    max,
    min
  });
}

function calculateWaterLevelGraphYAxisTicksAndHorizontalLines({
  waterLevelGraphDimensions
}: {
  waterLevelGraphDimensions: IGraphDimension;
}) {
  const { graphMin, graphMax, stride } = waterLevelGraphDimensions;
  const yAxisTicks: IGraphShellNewYAxisTicks = { type: 'ticks', ticks: [] };
  const horizontalLines: TGraphGridNewHorizontalLine[] = [];
  for (let index = graphMin; index <= graphMax; index += stride) {
    const normalizedY = normalizeValueWithinRange(graphMin, graphMax, index);

    yAxisTicks.ticks.push({
      normalizedY,
      label: index === graphMax ? { type: 'icon', id: 'icon-tide-high' } : { type: 'value', value: index }
    });

    horizontalLines.push({ normalizedY });
  }

  return {
    yAxisTicks,
    horizontalLines
  };
}
